import { Container, Stack, Row, Col } from 'react-bootstrap';
import './Logistica.css';
import { useTranslation } from "react-i18next";

export const Logistica = () => {

    const { t } = useTranslation();
    return (
      <Stack className="logistica">        
       {t("planning")}
       <Container className="legenda">
        <Row>
            <Col xs><div className='cuadrado cuadrado_azul'></div>{t("logistica.bus_ida")} - 20:00 PM</Col>            
            </Row>
             

            <Row>
            <Col xs><div className='cuadrado cuadrado_rojo'></div>{t("logistica.bus_vuelta")} - 03:00 AM - 05:00 AM</Col>
        </Row>
        <Row style={{marginTop: "20px", marginLeft: "10px"}}>
            <ul style={{textAlign: "start"}}>
            <li>Stops</li>
            <li>1. <a rel="noreferrer" href="https://maps.app.goo.gl/EEgYGYNYySD4ji888" target="_blank">Rotonda H. Real de la alhambra (H.Albero)</a></li>
            <li>2. <a rel="noreferrer" href="https://maps.app.goo.gl/yXY5Nsnsn2cqw44XA" target="_blank">H.Abades (Zaidin)</a></li>
           
            <li>3. <a rel="noreferrer" href="https://maps.app.goo.gl/wKoQ7e7KjcFLy3Lz7" target="_blank">Puerta Real(Barcelo, Eurostarts, Aurea Catedral...)</a></li>
            <li>4. <a rel="noreferrer" href="https://maps.app.goo.gl/SQGGV7vHUCJGfyds7" target="_blank">Hipercor/Mendez Nuñez (H. Luna de Granada, Urban Dream, Granada Center...)</a></li>           
            </ul>
            </Row>
       </Container>          


       <iframe title="mapa_boda" src="https://www.google.com/maps/d/u/1/embed?mid=1y-ypl6yNrKcdzs76EcSYdszcfCCtQeM&ehbc=2E312F" width="100%" height="480"></iframe>
      </Stack>
    );
  };